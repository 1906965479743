<template>
    <div></div>
</template>
<script>
    
import CryptoJS from 'crypto-js'
export default {
    data(){
        return {}
    },
    created(){
        let d = "c9FrhXPMpp+WKhaQkTvB1WbMcIa48d4tGuC9d9ahdXRLRplby+sW+YisEURF2YRMazHeBiUML0kUs/siiqeTj6NMXfkx+SWfPSiWPHpD8ZTAkMTmRNejwxpxJIpdTtZVjea1lAdhgy8elo7pBVfajA==";
        let d2 = "FWUrQmmdkBz4Kh7WrkFtGId4Fut2q0J+y1SnrudpPdT1E1ACyiyZVnLcjhoDOUOEG8wIc4E4iaJg+CN1aZ4Gw/fwA6ryE38cQCAJPfpWZaeRC89IY04S98DGyeWIHB18YPnb6jTdtSc2aBozbGHJPRKkOGSK2mru2eYlVcb5LV8/+JmfSfYwLJdy8Qbh4UGU";
        let d3 = "sOEvJfm1g+OAqmlKlbtx1QxzuKBKmP7VS7wZF3B2XYDZYS5XXB0+OcxBG53sZeqwyg+fp7SAupKA6gKn/OugpHty7RokJEne+Hw+pKNqdZQ+lgCu/VKwWoCyBunJXa1u7kB+m6DA5saHJa+6pzzFYjGHxTWHm1AkW5H5DTROlWr59ofJ8hvukknDFYxohpXJLf/TjBF7tLMkUZHBmjjKuUpCmtOw6rEekzrXfcmkifbvzJ0JkXCJqMQfcA3TPJ9cvaOQnaMSYomCvIZ2BCY8x+ZblBULGVI3nFE9NidP7G+f2GyEaM6b9NaYgn2chAgd2JtByR+9LUMOhpchGezRsoUFI5WIx6NfdaadkYBwLb67XksvjZg2YmtYp1QZdWGCcDeCpE5pZelqnTUZsUoEiHIYwdmLfLwq7NcVF9SDZbs=";
        let d4 = "NctoihEYchBG2H5n2mDpYV9tUadmhjp/vZkZQTnIvz9rGQJmdDZvGXWpom9rLuah/jDYP0LUf5hnR4J6YMf3JNkAWkDtUKKUwC0XtNuTsIHgj29De11c2Z/VTUSH+/fJlOLwl/QWe7zsdh6GqbKpqrbXsUP3hVh/GoyqGnKAC1croUkjPsMx+0Uf18PBoeYVaQfpKNJdmlj8RmZKvSryPSWOE4quQVZFSDuVvPfxX41LcGDjigDXUDLF/UDu8mEsAHrKqJXw2+r17oYEdMZ05JCDIDJiZ4B2FNRwN+d16MsonKbD4SHgKSh9Z1ueu8AFtB0kSAxO9kqLKA1cyfihHNJEiuhY7pIw7+BREY8Nfm1WirT7pfMArPyHIO0UcI2PZxMTxcf2+0vBHt7Ey9Q5rtwttPg2PaRSyPHzRvULZBI2PvQV3uESK+lCjaiFCRFN8r6LIUU9rU6EM4ks+1sTkijSPYm1Gi7Olwu9bGj2Bq4Kor54h7QwS9+z+ekECvAxEobPhS4MqLcz44YWNmILbbj4lH/QIjRpkd6CmTL9Li7BT2GikR07W8l0LqbaWOUIkVU6PGev1ZVZq/VblDfiBw==";
        let d5 = "fvFIkqPfRw2b4sErOtr/XHLYtA1Y3hHh8vwfcKGiNyCG3yv+0CKmdRUeBYsaQUy+3Ykd5JvPw2ozSvXxH8LNMp1cAndSjQ/7inKalG5NU9tisNn8LYDq2Va++PvgOJS0Bt59E36rTneQ+b4DTpJYr6wvdgJbNzlQhYg+HGZ7rCM=";
        let d6 = "ZD9n8DM+VbW6dAACI0KV+Syx0gLkbOTEgydQoORKW64efo9qBvzUhAT+bY0KvDekr9IQhT6Rf6FxiJVOytLwYCNM3SVGEd9XDyq/b44nZkaYnTGmDMKqz93JjRix9vk79rtcMONbjJsqQ0TzQwPSvMUO/zU1lgMx125qCqckUOZeOoZAH/qNw3UfHJj/jpH5V9c7auN2oS7Ite1FpbdqYaH8RXLlVwj6TqR8BxUxeaqqOZen+28pFrLIeQGzTf+tfATnhKc6CKQQUteM2XF/YU5q5lWBc+uMg1T2XtDLG619Lz0KEYkSATrrUKqWMyNA"
        const texta = this.decrypt(d6, "80634073898f8517", "mCZG81sKXkToFUpr");
        console.log(texta);
    },
    methods:{
        decrypt(data, AES_KEY, IV) {
            const key = CryptoJS.enc.Utf8.parse(AES_KEY);
            const iv = CryptoJS.enc.Utf8.parse(IV);
            const decrypt = CryptoJS.AES.decrypt(data, key, {
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }).toString(CryptoJS.enc.Utf8);
            return decrypt;
        }

    }
}
</script>
<style>

</style>